@font-face {
  font-family: 'avenir_lt_std85_heavy';
  src: url('fonts/avenirltstd-heavy-webfont-webfont.eot');
  src: url('fonts/avenirltstd-heavy-webfont-webfont.eot?#iefix') format('embedded-opentype'), url('fonts/avenirltstd-heavy-webfont-webfont.woff2') format('woff2'), url('fonts/avenirltstd-heavy-webfont-webfont.woff') format('woff'), url('fonts/avenirltstd-heavy-webfont-webfont.ttf') format('truetype'), url('fonts/avenirltstd-heavy-webfont-webfont.svg#fontawesomeregular') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'FontAwesome';
  src: url('fonts/fontawesome-webfont.eot');
  src: url('fonts/fontawesome-webfont.eot?#iefix') format('embedded-opentype'), url('fonts/fontawesome-webfont.woff2') format('woff2'), url('fonts/fontawesome-webfont.woff') format('woff'), url('fonts/fontawesome-webfont.ttf') format('truetype'), url('fonts/fontawesome-webfont.svg#fontawesomeregular') format('svg');
  font-weight: normal;
  font-style: normal;
}
:root {
  --schema-color: #5cacc1;
  --schema-color-dark-30: #5cacc1 - 30;
  --schema-color-dark-20: #5cacc1 - 20;
}
*:focus {
  outline: 0;
}
body {
  background: #fff;
  font: 12px Arial, Verdana, Tahoma, sans-serif;
  margin: 0;
}
._ {
  overflow-x: hidden;
}
._ .fix {
  position: fixed;
  top: -30px;
  z-index: 11000;
}
div.top-fix.visible {
  display: block;
}
div.top-fix {
  display: none;
  height: 165px;
  width: 1px;
}
.mobile-top-menu {
  display: none;
}
._ {
  background: #efefef;
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
  box-shadow: 0 0 70px 25px #A1A1A1;
  font-weight: normal;
  margin: 0;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  width: 980px;
}
._ textarea {
  resize: none;
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  height: 130px;
  padding: 4px;
  width: 602px;
}
._ a:hover {
  color: #3981b4;
  text-decoration: underline;
}
._ a:not(.button):not(.prod-btn) {
  color: #5cacc1;
  transition: all 0.1s ease-in-out 0s;
}
a img {
  border: none;
}
._ .fix .header {
  background-color: transparent;
  height: 66px;
}
._ .header {
  background-color: #efefef;
  height: 170px;
  margin-bottom: 15px;
}
._ .center-part {
  width: 980px;
}
._ .top .center-part .header div.home a:first-child {
  border: 0;
}
/*my cart 2*/
.my-cart-2 li a:hover {
  color: #111;
  text-decoration: none;
}
.my-cart-2 li a.cart {
  color: #444;
}
.my-cart-2 li a:hover {
  text-decoration: underline;
}
.my-cart-2 li a {
  color: #666;
  font-size: 11px !important;
  padding: 0 !important;
  text-decoration: none;
  text-transform: lowercase;
  transition: all 0.4s ease-in-out 0s;
}
.my-cart-2 li:first-child {
  background: none;
}
.my-cart-2 li {
  background: url("../img/bg-options.png") 8px 7px no-repeat;
  display: inline-block;
  float: left;
  padding-left: 18px;
}
.my-cart-2 {
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 10px;
  top: 5px;
}
._ .top .center-part .header div.my-cart {
  display: none;
  position: absolute;
  right: 10px;
  top: 32px;
}
._ .top .center-part .header div.my-cart a {
  font-size: 11px;
  padding-left: 5px;
  text-decoration: none;
  text-shadow: 0 0 2px #000;
  text-transform: uppercase;
}
._ div.div-search-field div {
  display: inline-block;
}
._ .fix div.div-search-field {
  z-index: 13000;
}
._ div.div-search-field {
  height: 22px;
  padding: 3px;
  position: absolute;
  right: 15px;
  text-align: right;
  top: 33px;
  z-index: 500;
}
._ div.div-search-field input {
  background-color: #fff;
  border: none;
  border-radius: 2px;
  color: #444 !important;
  font-size: 12px;
  left: 0;
  margin: 0;
  padding: 5px 30px 5px 5px;
  width: 115px;
}
._ div.div-search-field-submit button {
  background: url("../img/bg-search-submit.png") -4px -4px no-repeat;
  border: none;
  float: left;
  font-size: 0;
  height: 22px;
  margin: 0;
  padding: 0;
  width: 22px;
}
._ .over div.div-search-field-submit button {
  background-position: -4px -33px;
}
._ div.div-search-field-submit {
  position: absolute;
  right: 7px;
  top: 5px;
}
#advanced-search-form form {
  border-bottom: 1px solid #cecece;
  border-top: 1px solid #fff;
}
#advanced-search-form {
  background-color: #fff;
  border-bottom: 1px solid #fff;
  border-top: 1px solid #cecece;
  margin: 10px 0;
  padding: 0;
}
/*social icons*/
._ .menu.social-icons > ul > li.instagram {
  background-position: -255px 0;
}
._ .menu.social-icons > ul > li.instagram:hover {
  background-position: -287px 0;
}
._ .menu.social-icons > ul > li.tumblr {
  background-position: -190px 0;
}
._ .menu.social-icons > ul > li.tumblr:hover {
  background-position: -222px 0;
}
._ .menu.social-icons > ul > li.pinterest {
  background-position: -127px 0;
}
._ .menu.social-icons > ul > li.pinterest:hover {
  background-position: -158px 0;
}
._ .menu.social-icons > ul > li.twitter {
  background-position: -64px 0;
}
._ .menu.social-icons > ul > li.twitter:hover {
  background-position: -95px 0;
}
._ .menu.social-icons > ul > li.facebook:hover {
  background-position: -31px 0;
}
._ .menu.social-icons > ul > li > ul,
._ .menu.social-icons div.delimiter {
  background-color: #fff !important;
}
._ .menu.social-icons > ul > li.with-children:hover {
  background-color: #fff;
}
._ .menu.social-icons > ul > li {
  background: url("../img/social-icons.png") 0 0 no-repeat;
  height: 37px !important;
  width: 30px;
}
._ .menu.social-icons > ul {
  border: none;
}
._ .menu.social-icons {
  border: none;
  left: auto;
  right: 180px;
  z-index: 400;
}
/*big menu*/
._ .menu.big .top-title {
  height: 68px;
}
._ .menu.big .delimiter {
  background-color: #fff;
  bottom: 8px;
  height: 9px;
  margin-left: 0;
  margin-right: 0;
  z-index: 100;
}
._ .menu.big > ul > li > ul {
  background-color: #fff;
  border: 1px solid #adadad;
  border-radius: 0 5px 5px 0;
  display: none;
  left: -1px;
  list-style: none;
  margin: 0;
  padding: 6px 2px 2px 2px;
  position: absolute;
  top: 54px;
  z-index: 80;
}
._ .menu.big > ul > li > ul > li {
  min-width: 190px;
}
._ .menu.big > ul > li:hover {
  background-color: #fff !important;
  border: 1px solid #adadad !important;
  border-radius: 5px 5px 0 0;
}
._ .menu.big > ul > li.logo img {
  max-height: 80px;
}
._ .menu.big > ul > li.logo,
._ .menu.big > ul > li.logo:hover {
  background: none !important;
  border: 1px solid transparent !important;
}
._ .menu.big > ul > li > a,
._ .menu.big > ul > li > div > a {
  display: block;
  font-size: 26px;
  height: 56px;
  line-height: 56px;
  text-transform: uppercase;
  width: auto;
}
._ .menu.big > ul > li.selected {
  background-color: #fff !important;
}
._ .menu.big > ul > li.one-level {
  border-radius: 5px !important;
}
._ .menu.big > ul > li {
  border-radius: 5px;
  float: left;
  height: 60px;
  margin: 0 15px 0 0;
  padding: 0;
}
._ .menu.big > ul {
  border: none;
  margin: 0;
}
._ .fix .menu.big {
  display: none;
}
._ .menu.big {
  border: none;
  top: 90px;
  z-index: 100;
}
._ .fix .menu {
  border-bottom: 1px solid transparent;
}
._ .menu {
  border-bottom: 1px solid #fff;
  left: 10px;
  position: absolute;
  right: 10px;
  top: 30px;
  z-index: 200;
}
._ .menu > ul > li:hover > ul {
  display: block;
}
._ .menu > ul > li > ul > li:hover {
  background-color: #e7e6e6;
}
._ .menu > ul > li > ul > li {
  background: url("../img/bg-subcat.png") 5px 8px no-repeat;
  min-width: 160px;
  padding: 3px 6px;
  text-align: left !important;
}
._ .menu > ul > li > ul {
  background-color: #eaeaea;
  border: 1px solid #fff;
  display: none;
  left: -1px;
  list-style: none;
  margin: 0;
  padding: 2px;
  position: absolute;
  top: 35px;
}
._ .fix .mainMenu > ul {
  background-color: #EFEFEF;
  border-left: 1px solid #cecece;
  border-radius: 0 0 10px 10px;
  border-right: 1px solid #cecece;
  box-shadow: 0 0 5px #ccc;
}
._ .menu > ul {
  border: 1px solid transparent;
  border-bottom: 1px solid #cecece;
  border-top: 0;
  display: inline-block;
  margin: 0;
  padding: 0;
  width: 100%;
}
._ .menu > ul > li.with-children:hover {
  background-color: #eaeaea;
  border: 1px solid #fff;
  border-bottom: 1px solid #eaeaea;
}
._ .menu li:hover .delimiter {
  display: block;
}
._ .menu .delimiter {
  background-color: #eaeaea;
  bottom: -2px;
  display: none;
  height: 6px;
  left: 0;
  position: absolute;
  right: 0;
  z-index: 100;
}
._ .menu .top-title {
  height: 25px;
  position: relative;
}
._ .menu > ul > li {
  border: 1px solid transparent;
  display: inline-block;
  height: 32px;
  padding: 10px 0 0 0;
  position: relative;
}
._ .mainMenu > ul > li:first-child > div > a {
  border: none !important;
}
._ .mainMenu > ul > li.with-children:hover > div > a {
  border-left: 1px solid transparent;
}
._ .mainMenu > ul > li > div > a {
  border-left: 1px solid #d5c9d5;
}
._ .menu a {
  color: #6D6F74;
  display: inline-block;
  font-size: 12px;
  padding: 0 8px;
  text-decoration: none;
  transition: all 0.1s ease-in-out 0s;
}
._ .menu a:hover,
._ .menu a.selected {
  color: #5cacc1;
}
/*controls*/
#drop-sub-cats {
  display: none;
}
._ .cat-controls ul li div.view-type a.selected {
  background-color: #efefef;
  border: 1px solid #eae9e9;
  color: #666;
  text-decoration: none;
}
._ .cat-controls ul li div.view-type a {
  border: 1px solid transparent;
  color: #5cacc1;
  display: inline-block;
  font-size: 14px;
  padding: 3px 4px;
}
._ .dropdown a:hover {
  color: #5cacc1;
}
._ .dropdown a {
  color: #6d6f74;
  display: inline-block;
  font-size: 14px;
  overflow: hidden;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 190px;
}
._ .dropdown > a {
  line-height: 25px;
  width: 170px;
}
._ .dropdown ul li {
  padding: 3px 0;
}
._ .dropdown.opened ul {
  display: block;
}
._ .dropdown ul {
  background-color: #fff;
  border: 1px solid #fff;
  display: none;
  left: 0;
  margin: 0 !important;
  padding: 5px !important;
  position: absolute;
  top: 36px;
  width: 188px;
  z-index: 1000;
}
._ .dropdown {
  background: #fff url("../img/drop-arrow.png") 183px 16px no-repeat;
  cursor: pointer;
  padding-left: 5px !important;
  padding-right: 20px !important;
  position: relative;
  width: 175px;
}
._ .cat-controls > ul > li > div {
  background-color: #fff;
  height: 25px;
  padding: 6px;
}
._ .cat-controls > ul > li {
  display: inline-block;
  margin-right: 10px;
  vertical-align: top;
}
._ .cat-controls ul {
  list-style: none;
  margin: 10px 20px;
  padding: 0;
}
/*tabs*/
._ .cat-tabs ul li a {
  color: #6D6F74;
  font-size: 14px;
}
._ .cat-tabs ul li:hover a {
  color: #5CACC1;
}
._ .cat-tabs ul li.selected {
  background-color: #fff;
  border-top: 1px solid #5CACC1;
}
._ .cat-tabs ul li {
  border-top: 1px solid transparent;
  display: inline-block;
  padding: 7px 12px;
  transition: all 0.3s ease-in-out 0s;
}
._ .cat-tabs ul {
  margin: 20px 20px 0 20px;
  padding: 0;
}
._ .add-text-span {
  left: 0;
  position: absolute;
  top: 0;
  z-index: 200;
}
._ .cat-image img {
  height: 400px;
  width: 100%;
}
._ .cat-image {
  background: #fff;
  height: 400px;
  padding: 2px 0;
  position: relative;
}
/*categor*/
.category ._ .product-list {
  display: none;
}
/*index links*/
._ .index-text {
  padding: 15px;
}
._ .index-links li:hover a {
  opacity: 1;
}
._ .index-links li a {
  background-color: rgba(255, 255, 255, 0.6);
  bottom: 0;
  color: #6D6F74;
  font-family: Arial;
  font-size: 25px;
  left: 0;
  opacity: 0;
  padding-top: 60px;
  position: absolute;
  right: 0;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  top: 0;
  transition: all 0.6s ease-in-out 0s;
  vertical-align: middle;
}
._ .index-links li {
  display: inline-block;
  margin-right: 13px;
  position: relative;
}
._ .index-links {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 980px;
}
body.bg-index .main {
  padding-top: 0;
}
/*
._ .main {
	height: 1%;
	overflow: hidden;
	padding: 10px 0;
}
*/
._ .main .center-part div.d1 {
  color: #666;
  float: none;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  margin: 0;
  padding: 0;
  position: relative;
  width: auto;
}
._ .footer .center-part {
  background: url("../img/bg-footer.png") center 0 no-repeat;
  padding-top: 20px;
  position: relative;
}
._ .footer .bottom-menu li {
  display: inline-block;
}
._ .footer .bottom-menu {
  margin: 0 10px;
  padding: 10px 0 0 0;
  width: 650px;
}
._ .footer div.bottom-menu ul {
  padding: 0;
}
._ .footer div.bottom-menu ul li:first-child a {
  border: none !important;
}
._ .footer .bottom-menu ul li a {
  border-left: 1px solid #ccc !important;
  color: #6D6F74;
  font-size: 11px;
  padding: 0 4px 0 7px;
  text-decoration: none;
}
._ .footer .bottom-menu a:hover {
  color: #5cacc1;
}
._ .footer .footer-text {
  color: #988c80;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 11px;
  padding: 4px 10px;
}
._ h1,
._ h2,
._ .div-modal h2 {
  color: #6D6F74;
  display: block;
  font-family: avenir_lt_std85_heavy, Arial, Helvetica;
  font-size: 22px;
  font-weight: normal;
  line-height: 30px;
  margin: 0;
  padding: 5px 10px 10px 15px;
  text-transform: uppercase;
}
._ h2 {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: bold;
  padding-left: 0;
  text-transform: none;
}
#page-product {
  display: none;
}
#text-content p {
  color: #555;
  font-family: Arial;
  font-size: 12px;
  padding: 10px;
}
div.content-body {
  font-size: 12px;
  padding: 15px;
}
#text-content a,
#payment-setup a {
  color: #393f95;
  font-size: 13px;
  text-decoration: underline;
}
._ div.div-modal {
  font-size: 13px;
  margin: 20px 20px 20px 0;
  text-align: center;
  width: auto;
}
._ div.div-modal table {
  margin: 0 auto;
  text-align: center;
}
._ .div-include div.div-border,
.bg-payment ._ div.div-border {
  width: 700px !important;
}
._ div.div-border {
  background: #fefefe;
  border: 1px solid #cecece;
  border-radius: 10px;
  box-shadow: 0 0 4px #ccc;
  display: inline-table;
  margin: 0 auto;
  min-width: 240px;
  padding: 10px 20px;
}
._ .checkout fieldset {
  background-color: #fbfbfb;
  margin: 35px 180px;
}
._ .register-comments-top {
  margin: 10px;
  max-width: 550px;
}
._ .user-register .register-comments {
  margin: 30px 5px 5px;
}
._ .product-view fieldset,
._ fieldset.budget-settings {
  max-width: none;
}
._ fieldset {
  border: 1px solid #cecece;
  border-radius: 6px;
  font-size: 12px;
  margin: 25px auto;
  padding: 35px 5px 20px;
  position: relative;
  text-align: left;
}
._ fieldset table,
._ fieldset div.order-misc {
  margin-left: 55px;
}
._ div.checkout-comment {
  text-align: center;
}
._ fieldset.budget-settings div.buttons {
  padding: 20px;
}
._ fieldset div.buttons {
  text-align: center;
}
._ td.value,
._ fieldset span.money {
  font-weight: bold;
}
._ fieldset span.money {
  padding-left: 15px;
}
._ fieldset td.label,
._ #customer-info .info-override td.label {
  border-bottom: 1px dotted #ccc;
  width: 200px;
}
._ fieldset .tax-table td.label {
  border-bottom: 1px solid #bbb;
}
._ fieldset div.change-method-note a,
._ fieldset div.change-method-note label {
  font-weight: normal;
}
._ fieldset div.change-method-note {
  margin-left: 35px;
  padding: 20px;
  font-weight: bold;
}
._ div.preview-order-note {
  font-size: 11px;
  margin-left: 55px;
  margin-right: 55px;
}
._ legend {
  background-color: #fff;
  border: 1px solid #cecece;
  border-radius: 4px;
  color: #222;
  font-size: 14px;
  left: 10px;
  padding: 7px 8px;
  position: absolute;
  top: -16px;
}
._ div.div-modal fieldset table td.control {
  width: 200px;
}
._ div.div-modal table td {
  text-align: left;
}
._ select {
  border: 1px solid #e1e1e1;
  border-radius: 2px;
  font-size: 12px;
  height: 26px;
  margin: 2px;
  padding: 4px;
}
._ div.div-modal select {
  width: 202px;
}
._ input[type="text"],
._ input[type="password"],
._ #advanced-search-form input {
  background-color: #fff;
  border: 1px solid #e1e1e1;
  border-radius: 2px;
  font-family: Arial, sans-serif;
  font-size: 12px;
  line-height: 17px;
  margin: 2px !important;
  padding: 4px 6px;
}
._ fieldset input[type="text"],
._ fieldset input[type="password"] {
  width: 189px;
}
._ label.required {
  background: url("../img/asterisk.png") 99% 4px no-repeat;
  display: block;
  padding-right: 15px;
}
._ .center-part button:disabled:not(.custom-button) {
  background: #e6e6e6 !important;
  color: #999 !important;
}
._ div.div-border button {
  margin: 10px 2px;
}
._ button:not(.custom-button),
.button,
._ .product-list2 a.prod-btn {
  background: #e6e6e6 url("../img/menu/bg-menu-21.png") 0 0 repeat-x;
  border: 1px solid #ccc;
  border-radius: 2px;
  color: #5CACC1;
  cursor: pointer;
  display: inline-block;
  font-size: 12px;
  height: 27px;
  margin: 10px;
  padding: 0 10px;
  text-decoration: none !important;
  text-align: center;
  white-space: nowrap;
}
._ a.button {
  line-height: 27px;
}
._ button:hover:not(.custom-button),
.button:hover,
._ .product-list2 a.prod-btn:hover {
  background-position: 0 -56px;
  border: 1px solid #bbb;
  color: #5CACC1;
}
._ div.checkout-link a.continue,
div.checkout-link a.checkout,
#quote-request-link a {
  line-height: 27px;
  margin: 2px;
}
._ .list div .message {
  font-size: 12px;
}
._ div .message {
  font-size: 13px;
  margin: 2px;
  padding: 2px 2px 2px 10px;
  text-align: left;
}
._ div .message.info {
  font-size: 14px;
  padding-right: 12px;
}
._ div .message.success {
  border: 0;
  border-radius: 5px;
  font-size: 14px;
  min-height: 40px;
}
._ div div.message.error {
  color: #fc3838;
  padding: 5px 5px 5px 30px;
}
._ div .div-modal form .message.error {
  border: 0;
  color: #fc3838;
}
._ .product-list-top-part > div {
  display: inline-block;
  padding-top: 1px;
}
._ .product-list-top-part {
  padding: 0 90px 0 15px;
  position: relative;
  vertical-align: top;
}
.category ._ .product-list-sort-menu {
  display: none !important;
}
._ .page-size-list {
  float: right;
}
._ .list-grid ul li a:hover {
  opacity: 1;
}
._ .list-grid ul li.line a {
  background-position: 0 -16px;
}
._ .list-grid ul li a {
  background: url("../img/bg-view-type.png") 0 0 no-repeat;
  display: block;
  height: 16px;
  opacity: 0.7;
  transition: all 0.7s ease-in-out 0s;
  width: 16px;
}
._ .list-grid ul li.selected {
  background-color: #fff;
}
._ .list-grid ul li {
  border: 1px solid #e1e1e1;
  border-radius: 2px;
  display: inline-block;
  float: left;
  margin-left: 3px;
  padding: 4px;
}
._ .list-grid ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
._ .list-grid {
  position: absolute;
  right: 27px;
  top: 2px;
}
pagination-menu.bottom {
  margin-bottom: 35px;
}
.category ._ .pagination-menu {
  display: none;
}
._ .pagination-menu {
  margin-top: 1px;
  padding: 0 10px 0 40px;
  padding-top: 7px !important;
  vertical-align: top;
}
._ .pagination-menu a {
  border: 1px solid #cecece;
  border-radius: 2px;
  color: #6D6F74;
  padding: 4px 7px;
  text-decoration: none !important;
}
._ div.pagination-menu a.disabled {
  color: #666 !important;
  cursor: default;
  opacity: 0.5;
}
._ .pagination-menu a:hover,
._ .pagination-menu a.selected {
  background: #fff;
  border: 1px solid #cecece;
  color: #5cacc1 !important;
}
table.product-list {
  border-collapse: collapse;
  margin: 20px 0 0 0;
  padding: 0;
}
table.product-list td {
  margin: 0;
  padding: 0;
}
._ .over .list-zoom {
  opacity: 1 !important;
}
._ .list-zoom {
  background: url("../img/zoom.png") center center no-repeat;
  bottom: 0;
  display: block;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: all 0.5s ease-in-out 0s;
  z-index: 400;
}
._ .product-list2 a:hover {
  color: #575757;
}
._ .product-list2 {
  font-size: 14px;
  margin: 0 0 0 17px;
  width: 1000px;
}
._ .product-list2 div.item.over {
  background-color: #fff;
}
._ .product-list2.line.with-price-tables div.item {
  width: 935px;
  min-height: 270px !important;
}
._ .product-list2.line div.item {
  transition: all 0.1s ease-in-out 0s;
  width: 450px;
}
._ .overflow .product-list2 div.item {
  height: 305px;
}
._ .product-list2 div.item {
  background-color: #f1f1f1;
  border: 1px solid #ebe8e8;
  float: left;
  height: 290px;
  margin: 0 20px 20px 0;
  padding-left: 10px;
  position: relative;
  transition: all 0.2s ease-in-out 0s;
  width: 210px;
}
._ .product-list2 div.item div.image a {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  width: 200px;
  height: 200px;
  background-color: #fff;
  border: 1px solid #ebe8e8;
}
._ .product-list2.line.with-price-tables div.item div.image {
  top: 38px;
}
._ .product-list2 div.item div.image {
  height: 200px;
  position: absolute;
  left: 8px;
  top: 8px;
  text-align: center;
  vertical-align: middle;
  width: 200px;
}
._ .product-list2 div.item div.image img {
  max-height: 200px;
  max-width: 200px;
}
._ .product-list2.line div.item div.description {
  display: block;
  font-size: 11px;
  left: 225px;
  padding-right: 6px !important;
  position: absolute;
  top: 20px;
}
._ .product-list2 div.item div.description {
  display: none;
}
._ .overflow .product-list2 div.item div.name {
  margin-top: 220px;
  opacity: 1;
}
._ .product-list2.line div.item div.name {
  width: auto;
}
._ .product-list2 div.item div.name {
  height: 19px;
  position: relative;
  margin-top: 220px;
  width: 198px;
  opacity: 0.01;
}
._ .product-list2 div.item.over div.name a {
  color: #6D6F74;
}
._ .product-list2.line div.item div.name a {
  width: inherit;
}
._ .overflow .product-list2 div.item div.name a {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
._ .product-list2 div.item div.name a {
  color: #6D6F74;
  display: block;
  font-size: 16px;
  text-decoration: none;
  width: 195px;
}
._ .product-list2 div.item div.category {
  display: none;
}
._ .product-list2 div.item div.sku {
  font-size: 11px;
  font-weight: normal;
  padding: 1px 1px 0 0;
  position: absolute;
  left: 7px;
  top: 1px;
  z-index: 10;
}
._ .product-list2 a.prod-btn {
  margin: 2px;
}
._ .product-list2.line a.mailer,
._ .product-list2.line a.quick-view {
  display: inline-block;
}
._ .product-list2 a.mailer,
._ .product-list2 a.quick-view {
  display: none;
}
._ .product-list2 div.div-prod-btn {
  bottom: 10px;
  line-height: 27px;
  margin: 0;
  position: absolute;
  right: 10px;
  z-index: 100;
}
._ .product-list2 div.item.on-sale div.price .reg-price {
  color: #888;
  font-size: 15px;
  margin-left: 6px;
  text-decoration: line-through !important;
}
._ .product-list2 div.item div.price {
  bottom: 40px;
  color: #64b3eb;
  font-size: 15px;
  font-weight: bold;
  left: 10px;
  position: absolute;
  z-index: 20;
}
._ .product-list2 button {
  bottom: 20px;
  position: absolute;
  right: 0;
}
._ td.CAD {
  background: url("../img/cad.png") 4px 5px no-repeat;
  padding-left: 25px !important;
}
._ td.USD {
  background: url("../img/usd.png") 4px 5px no-repeat;
  padding-left: 25px !important;
}
._ .main .center-part div.d1.cart {
  margin-right: 20px;
  width: 690px;
}
._ table div.options > ul .li-elements,
._ table div.options > ul .li-elements li {
  font-weight: bold;
  padding: 0 !important;
}
._ table div.options .single {
  font-weight: bold;
}
._ table div.options li a {
  text-decoration: underline;
}
._ table div.options > ul li:first-child {
  background: none;
}
._ table div.options > ul li {
  background: url("../img/bg-options.png") 2px 6px no-repeat;
}
._ table div.options > ul li,
._ table div.options > ul ul {
  display: inline-block;
  margin: 0;
  padding: 0 0 0 10px;
}
._ table div.options > ul {
  list-style: none;
}
._ table div.options ul {
  margin: 2px 0;
  padding: 0 20px;
}
._ table div.options {
  border: 1px solid #eee;
  border-radius: 6px;
  color: #444;
  font-size: 12px;
  margin: 10px 2px;
  padding: 5px;
}
._ .ch-prod-list {
  width: 612px !important;
}
._ .cart table.list div.item-controls {
  position: relative;
  width: 99px;
}
._ .cart div.checkout-link a.checkout {
  margin-right: 0;
}
._ .cart div.checkout-link {
  margin: 15px 20px 15px 0;
  position: relative;
  text-align: right;
}
/*cart buttons*/
._ button.recalculate:hover span {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
._ button.recalculate span {
  background: url("../img/bg-cart-buttons.png") 0 1px no-repeat;
  display: block;
  height: 18px;
  left: 5px;
  position: absolute;
  top: 5px;
  transition: all 0.5s ease-in-out 0s;
  width: 18px;
}
._ button.remove span.span-1 {
  background: url("../img/bg-cart-buttons.png") -46px -4px no-repeat;
  display: block;
  height: 12px;
  left: 7px;
  position: absolute;
  top: 10px;
  width: 14px;
}
._ button.remove:hover span.span-2 {
  -webkit-transform: rotate(-26deg);
  transform: rotate(-26deg);
}
._ button.remove span.span-2 {
  background: url("../img/bg-cart-buttons.png") -32px 0 no-repeat;
  display: block;
  height: 4px;
  left: -7px;
  position: absolute;
  top: 6px;
  transition: all 0.5s ease-in-out 0s;
  width: 28px;
}
._ .cart form button.recalculate,
._ .cart form button.remove {
  background: #fff;
  border: 1px solid #e1e1e1 !important;
  box-shadow: none;
  color: transparent;
  float: left;
  height: 30px;
  margin: 4px 0 0 12px;
  opacity: 0.6;
  position: relative;
  text-decoration: underline;
  transition: all 0.2s ease-in-out 0s;
  width: 30px;
}
._ .cart form button.remove {
  float: right;
  margin: 4px 12px 0 0;
}
._ .cart form button.recalculate:hover,
._ .cart form button.remove:hover {
  opacity: 1;
}
/*cart*/
._ .cart {
  font-size: 13px;
  padding-bottom: 25px;
}
._ table.list img {
  max-height: 120px;
  padding: 10px;
}
._ .cart table.list a {
  font-size: 13px;
  text-decoration: underline;
}
._ .cart table.list span.money {
  font-weight: bold;
}
._ .cart table.list td.quantity {
  text-align: center;
  width: 70px;
}
._ .cart table.list td.image {
  padding: 3px;
  text-align: center;
  width: 160px;
}
._ table.list,
._ table.shipping-methods {
  border-collapse: collapse;
  margin: 0 auto;
  width: 98%;
}
._ .product-view div.name {
  display: none;
}
._ .product-view div.name,
._ .product-view div.sku,
._ .product-view div.sku label,
._ .product-view div.name label {
  font-size: 15px !important;
  font-weight: bold;
}
._ .product-view div.name label,
._ .product-view div.sku label {
  font-weight: normal;
}
._ .product-view div.description div.text {
  font-size: 12px;
  min-height: 290px;
  padding-top: 30px;
}
._ .product-view div.zoom a:hover {
  color: #5cacc1;
  text-decoration: underline;
}
._ .product-view div.zoom a {
  bottom: 2px;
  color: #6d6f74;
  display: inline-block;
  font-size: 11px;
  position: absolute;
  right: 10px;
  text-decoration: none;
}
._ .product-view .cart-form {
  border-top: 1px solid #cecece;
  margin-top: 30px;
}
._ .product-view form {
  border-top: 1px solid #fff;
  margin-bottom: 10px;
  padding: 10px;
}
._ #pr_form_id_quantity,
._ .mult-quantity input {
  text-align: center;
  width: 50px !important;
}
._ table.price-table .on-sale .reg-price {
  color: #888;
  text-decoration: line-through !important;
}
._ table.price-table {
  border-collapse: collapse;
  margin: 10px 0;
}
._ .product-view {
  font-size: 14px;
  margin: 10px;
}
._ .product-view div.image-inner {
  display: table-cell;
  height: 320px;
  opacity: 0.95;
  text-align: center;
  vertical-align: middle;
  width: 320px;
}
._ .product-view div.image {
  background-color: #fff;
  border: 1px solid #cecece;
  border-radius: 10px;
  float: left;
  margin-right: 15px;
  min-height: 250px;
  position: relative;
  text-align: center;
}
._ .product-view .product-comments,
._ .cart-comments {
  font-family: "Arial", "Helvetica", "Verdana", "sans-serif";
  font-size: 11px;
  font-weight: normal;
  padding: 5px;
}
/*cart form*/
._ .cart-form .submit.mult-ordering {
  text-align: center;
}
._ .cart-form .submit {
  margin-top: 20px;
}
._ .cart-form .in-line div.label {
  border: none !important;
}
._ .cart-form .in-line label {
  cursor: pointer;
}
._ .cart-form li div.label {
  border-bottom: 1px dotted #ccc;
  min-width: 200px;
}
._ .cart-form li div {
  display: inline-block;
}
._ .cart-form h4 {
  border-bottom: 1px solid #ccc;
  color: #333;
  font-size: 15px;
}
._ .attachments-submit {
  display: none;
}
._ .attachments-submit.no-attachments {
  display: block;
}
._ .attachments-error {
  background-color: #fff;
  border: 1px solid #ddd;
  bottom: 5px;
  box-shadow: 0 0 3px #ccc;
  display: none;
  left: 5px;
  padding: 5px;
  position: absolute;
  right: 5px;
  top: 0;
}
.attachment-link a {
  color: #444;
  display: inline-block;
  margin: 2px 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 230px;
}
._ .attachment-link a span {
  display: none;
}
._ .attachment-data iframe {
  border: none;
  height: 180px;
  overflow: hidden;
  width: 285px;
}
._ .attachment-data svg {
  cursor: pointer;
  height: 130px;
  width: 283px;
}
.svg-preview svg {
  height: 300px;
  width: 500px;
}
._ li:hover .attachment-data {
  border: 1px solid #00a8eb;
  box-shadow: 0 0 4px #aaa;
  transition: all 0.2s ease-in-out 0s;
}
._ .attachment-data {
  background-color: #eee;
  border: 1px solid #fff;
  box-shadow: 0 0 4px #ccc;
  display: block !important;
  height: 180px;
  padding: 1px;
  text-align: center;
  width: 285px;
}
._ li.unset .attachment-mark {
  display: none;
}
._ .attachment-mark {
  background: url("../img/att-mark.png") 0 -20px no-repeat;
  bottom: 10px;
  height: 20px;
  position: absolute;
  right: 2px;
  width: 20px;
}
._ .attachment-mark.loading {
  background: rgba(255, 255, 255, 0.8) url("../img/preloader.gif") center center no-repeat;
  bottom: 0;
  display: block !important;
  height: 186px;
  left: 5px;
  opacity: 0.7;
  width: 287px;
}
ul.attachments li {
  display: inline-block;
  position: relative;
  width: 280px;
}
._ .cart-form ul li {
  margin-right: 10px;
  min-height: 20px;
  padding: 0 5px;
}
._ .cart-form ul {
  list-style: none;
  margin: 10px;
  padding: 0;
}
._ th,
._ td.subtotal[colspan="5"] {
  background-color: #f6f6f6;
  border: 1px solid #bbb;
  color: #555;
  padding: 4px;
}
._ th {
  padding: 11px 5px;
}
._ div.div-include {
  margin: 0 10px;
}
._ td.tools {
  text-align: center;
}
._ table.list td,
._ table.price-table td,
._ table.shipping-methods td,
._ .budget-ord-list table td {
  background-color: #fff;
  border: 1px solid #bbb;
  color: #555;
  padding: 2px 5px;
  vertical-align: top;
}
._ table.list td.subtotal,
._ table.list td.unit-price {
  padding-right: 3px;
  text-align: right;
  white-space: nowrap;
}
._ td.tools {
  text-align: center;
  width: 60px;
}
._ .cart table.list td input {
  width: 50px;
}
#payment-method-list .usr-prompt {
  font-weight: bold;
}
#payment-method-list .method-desc {
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
  height: 30px;
  line-height: 25px;
}
#payment-method-list {
  font-size: 12px;
  margin: 0 auto;
  width: 90%;
  padding: 10px 0;
  text-align: left;
}
#payment-method-list li {
  border-bottom: 1px solid #cecece;
  font-size: 13px;
  height: 45px;
  list-style: none;
  margin-bottom: 10px;
  padding: 0 0 0 40px;
  position: relative;
  width: auto;
}
#payment-method-list li.selected,
#payment-method-list li:hover,
#payment-method-list li.paypalexpresscheckout:hover {
  border-bottom: 1px solid #44a7ed;
  color: #5cacc1;
  transition: all 0.3s ease-in-out 0s;
}
#payment-method-list li.paypalexpresscheckout {
  background: url("../img/paypal.gif") 40px 3px no-repeat;
  padding-left: 96px;
}
._.mobile-version fieldset table,
._.mobile-version fieldset div.order-misc {
  margin-left: 10px !important;
}
._ .payment-methods-text {
  margin: 15px;
}
#billing-information,
#shipping-information {
  border-radius: 6px 0 6px 6px;
  padding-top: 50px;
}
._ fieldset .register-checkbox * {
  font-size: 11px;
  height: 21px;
  line-height: 21px;
  vertical-align: middle;
}
._ fieldset .register-checkbox label {
  cursor: pointer;
  padding-left: 5px;
}
._ fieldset .register-checkbox-wrapper {
  height: 40px;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
}
._ fieldset .register-checkbox {
  border-right: 0;
  border-top: 0;
  padding: 1px 6px;
  position: absolute;
  right: -400px;
  top: 0;
}
._ .policy .ch-div,
._ .policy .ch-label {
  display: inline-block;
}
._ .policy {
  margin-bottom: 20px;
  padding: 15px 40px;
  text-align: left;
}
._ .subscribe {
  position: absolute;
  right: 20px;
  top: 25px;
  width: 260px;
}
._ .subscribe .title {
  color: #6D6F74;
  font-family: avenir_lt_std85_heavy, Arial;
  font-size: 16px;
  margin-bottom: 8px;
  text-transform: uppercase;
}
._ .subscribe .white-line button:hover {
  text-decoration: underline;
}
._ .subscribe .white-line button {
  background: none;
  border: none;
  box-shadow: none;
  color: #5CACC1;
  font-family: avenir_lt_std85_heavy, Arial;
  font-size: 16px;
  margin: 0 !important;
  position: absolute;
  right: 5px;
  text-transform: uppercase;
  top: 5px;
}
._ .subscribe .white-line input {
  background-color: #fff;
  border: none;
}
._ .subscribe .white-line {
  background-color: #fff;
  padding: 5px;
  position: relative;
}
._ .no-bg {
  background-image: none !important;
}
/*zooming-advanced v.1.0.1*/
.zoomWindowContainer {
  width: 1px !important;
}
.zoomWindow {
  border: 4px solid #fff !important;
  border-radius: 0;
  box-shadow: 0 0 5px rgba(200, 200, 200, 0.4);
  height: 320px !important;
  left: 330px !important;
  position: absolute;
  top: -4px !important;
  width: 320px !important;
}
.zoomLens {
  max-height: 300px !important;
  max-width: 300px !important;
}
.zoom-advanced {
  bottom: 2px;
  position: absolute;
  right: 10px;
}
.zoom-advanced a {
  font-size: 10px;
  text-decoration: none;
}
._ .product-view .pricing-info {
  clear: both;
  padding: 30px 0 0 30px;
}
._ .product-view div.image-inner.loading,
.loading {
  background: url(../img/preloader.gif ) center center no-repeat;
}
/*cart attachments preview*/
._ .attachment-preview li iframe {
  border: none;
  height: 130px;
  overflow: hidden;
  visibility: hidden;
  width: 160px;
}
._ .attachment-preview li.loading {
  background: url("../img/preloader.gif") center center no-repeat;
}
._ .attachment-preview li {
  border: 1px solid #ccc;
  padding: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
}
._ .attachment-preview {
  list-style: none;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: relative;
}
._ li.active a.cart-zoom {
  opacity: 1;
  right: 5px;
}
._ li:hover a.cart-zoom {
  right: 5px;
}
._ li.active a.cart-edit {
  left: 5px;
  opacity: 1;
}
._ li:hover a.cart-edit {
  left: 5px;
}
._ a.cart-zoom {
  background: rgba(0, 0, 0, 0.4) url("../img/zoom.png") center center no-repeat;
  right: -60px;
}
._ a.cart-edit {
  background: rgba(0, 0, 0, 0.4) url("../img/edit.png") center center no-repeat;
  left: -60px;
}
._ a.cart-zoom:hover,
._ a.cart-edit:hover {
  opacity: 1 !important;
}
._ li:hover a.cart-zoom,
._ li:hover a.cart-edit {
  opacity: 0.7;
}
._ a.cart-zoom,
._ a.cart-edit {
  border-radius: 36px;
  bottom: 30px;
  display: block;
  height: 44px;
  opacity: 0;
  padding: 12px;
  position: absolute;
  transition: all 0.7s ease-in-out 0s !important;
  width: 44px;
  z-index: 200;
}
._ .product-quantity span {
  font-weight: bold;
}
._ .product-quantity {
  font-size: 13px;
  padding-top: 10px;
}
/*shipping message*/
._ div.shipping-messages ul li {
  background: url("../img/dot-red.png") 10px 9px no-repeat;
  font-size: 11px;
  font-weight: normal;
  padding-left: 20px;
}
._ div.shipping-messages {
  background-color: #f9edbe;
  border: 1px solid #f0c36d;
  border-radius: 4px;
  margin: 10px;
}
._ div.shipping-messages ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
._ div.shipping-messages ul li.error {
  color: red;
}
#fancybox-wrap {
  z-index: 99999 !important;
}
/*shipping information*/
#customer-info fieldset {
  display: inline-block;
  width: 500px;
}
#customer-info {
  border-bottom: 1px solid #ccc;
  margin: 20px 10px 35px;
  text-align: center;
}
.div-submit {
  margin: 10px;
}
.div-include h3 {
  margin: 10px;
}
._ .tools.buttons a {
  margin: 2px;
  min-width: 70px;
}
._ #customer-info .tools {
  margin: 0;
}
._ .tools.buttons {
  margin: 0;
}
._ .tools {
  margin: 10px;
  padding: 10px 10px 10px 6px;
  text-align: right;
}
/*return-usr-txt*/
._ div.div-modal .return-usr-txt a:not(.button) {
  color: #2d86c8;
}
.return-usr-txt h2 {
  font-size: 20px;
}
.return-usr-txt {
  margin: 10px auto;
  text-align: center;
  font-size: 13px;
}
._ .customer-info div.preview-order-note {
  font-size: 11px;
  margin: 0;
}
._ .customer-info div.div-border {
  width: 590px;
  margin: 0 auto;
}
/*FontAwesome*/
._ .popup-checkout-link .fa:before {
  font-size: 12px !important;
}
._ .fa:before {
  font-family: FontAwesome;
  padding-right: 5px;
}
._ .fa-cart:before {
  content: "\f07a";
}
._ .fa-checkout:before {
  content: "\f046";
}
._ .fa-back:before {
  content: "\f0a8";
}
._ .fa-cancel:before {
  content: "\f05e";
}
._ button.fa div {
  display: inline-block;
}
._ button.print:before,
._ .fa-print:before {
  content: "\f02f";
  font-family: FontAwesome;
  padding-right: 6px;
}
/*my budget link*/
.my-budgets {
  top: 5px;
  left: 20px;
}
.my-budgets a {
  font-size: 11px;
}
._ div.sale-corner {
  background: url("../img/sale.png") 0 0 no-repeat;
  height: 68px;
  position: absolute;
  right: 0;
  top: 0;
  width: 68px;
  z-index: 200;
}
._ .bootstrap {
  margin: 0 10px !important;
}
